import Nav from "react-bootstrap/Nav";
import Image from 'react-bootstrap/Image';

export default function ImageLink({link, src, className}){
    return (
	<Nav>
	    <Nav.Item>
		<Nav.Link href={link}>
		    <Image className={className} src={src}/>
		</Nav.Link>
	    </Nav.Item>
	</Nav>
    )
}
