import Form from 'react-bootstrap/Form';
import FormControl from './FormControl';

export default function InputField({id, type, label, placeholder}){
    return (
	<FormControl id={id} label={label}>
	    <Form.Control type={type} placeholder={placeholder}/>
	</FormControl>
    )
}
