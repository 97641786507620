import Container from 'react-bootstrap/Container';

export default function ReferenceLink({title, link, children}){
    return (
	<Container>
	    <p className="reference-header">
		{title}
	    </p>
	    <p className="reference-link">
		<a href={link}>{link}</a>
	    </p>
	    <p className="reference-body">
		{children}
	    </p>
	</Container>
    )
}
