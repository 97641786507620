import Container from 'react-bootstrap/Container';

export default function SubHeader({title, children}){
    return (
	<Container>
	    <h2>{title}</h2>
	    {children}
	    <hr/>
	</Container>
    )
}
