import Container from 'react-bootstrap/Container';
import ResumeContent from '../components/ResumeContent';
import SubHeader from '../components/SubHeader.js';

export default function Resume(){
    return (
	<Container>
	    <SubHeader title="Resume">
		<p className="header-subtext">
		    Look at a PDF version of my resume here: <a href="/Resume-Derek-Swank.pdf">Resume-Derek-Swank.pdf</a>
		</p>
	    </SubHeader>
	    <ResumeContent />
	</Container>
    )
}
