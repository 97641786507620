import Container from 'react-bootstrap/Container';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header.js';
import {GenerateLinks} from './components/Header.js';
import Footer from './components/Footer.js';
import Author from './components/Author.js';
import ImageLink from './components/ImageLink.js';
import HomePage from './pages/Home.js';
import ContactPage from './pages/Contact.js';
import AboutAuthor from './pages/AboutAuthor.js';
import AboutWebsite from './pages/AboutWebsite.js';
import Resume from './pages/Resume.js';
import Portfolio from './pages/Portfolio.js';
import inbluelogo from './images/inbluelogo.png'

//import './App.css';

const links = [
    {text: "Home",
     link: "/",},
    {text: "Blog",
     link: "/blog",},
    {text: "Resume",
     link: "/resume",
     type: "dropdown",
     sublinks: [
	 {text: "Resume",
	  link: "/resume",},
	 {text: "Portfolio",
	  link: "/portfolio",},
     ]},
    {text: "Contact",
     link: "/contact",},
    {text: "About",
     link: "/about",
     type: "dropdown",
     sublinks: [
	 {text: "About Author",
	  link: "/about-author",},
	 {text: "About Website",
	  link: "/about-website",},
     ]},
]

const footerLinks = [
]

function App() {
    return (
	<Container fluid className="App">
	    <BrowserRouter>
		<Header title="Website">
		    {GenerateLinks(links, "headerLinks")}
		</Header>
		<Routes>
		    <Route path="/" element={<HomePage />} />
		    <Route path="/contact" element={<ContactPage />} />
		    <Route path="/about-author" element={<AboutAuthor />}/>
		    <Route path="/about-website" element={<AboutWebsite />}/>
		    <Route path="/resume" element={<Resume />}/>
		    <Route path="/portfolio" element={<Portfolio />}/>
		    <Route path="*" element={<Navigate to="/" />} />
		</Routes>
		<br/>
		<Footer titleLinks={links} links={footerLinks}>
		    <Author>
			Created by Derek Swank
		    </Author>
		    <ImageLink className="ln-image" link="https://www.linkedin.com/in/derek-swank-23b230114" src={inbluelogo}/>
		</Footer>
	    </BrowserRouter>
	</Container>
    );
}

export default App;
