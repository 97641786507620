import Container from 'react-bootstrap/Container';
import SubHeader from '../components/SubHeader.js';

export default function AboutAuthor(){
    return (
	<Container>
	    <SubHeader title="About Author"/>
	    <p>
		My name is Derek Swank and I am a recent University of Oregon graduate with a B.S. in Computer Science. My passions are in writing elegant, quality, open source software. I am particularly partial to the functional programming paradigm, though I am comfortable with OOP and imperative paradigms as well. I am currently in the process of increasing my depth of knowledge of platform-specific technologies - such as React, React Native, and Flask. 
	    </p>
	    <p>
		Professionally, I have six (6) years of experience working in IT, including 4 years of experience working in information security positions. With my degree in hand, I am seeking to transition to a career in software development, and I am building my portfolio in pursuit of such. I have been regarded as a methodical, focused, and personable employee in my previous positions, and I welcome the opportunity to blend my problem solving and software development skills towards a career.
	    </p>
	</Container>
    )
}
