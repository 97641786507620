import Stack from 'react-bootstrap/Stack';
import Nav from 'react-bootstrap/Nav';

export default function TitledStack({titleLink, children}){

    return (
	<Stack direction="vertical" className="titled-stack">
	    <Nav className="title-nav">
		<Nav.Item>
		    <Nav.Link className="title-link" href={titleLink.link}>{titleLink.text}</Nav.Link>
		</Nav.Item>
	    </Nav>
	    {children}
	</Stack>
    );
    
}
