import Container from 'react-bootstrap/Container';
import SubHeader from '../components/SubHeader.js';
import ReferenceLink from '../components/ReferenceLink.js';

export default function Portfolio(){
    return (
	<Container>
	    <SubHeader title="Portfolio" />
	    <ReferenceLink title="Save Game Editor for the Video Game “Green Hell” (C#/.NET)"
			   link="https://gitlab.com/dswanky/green-hell-save-game-editor">
		This is a program that modifies the game state save file for the video game <a href="https://store.steampowered.com/app/815370/Green_Hell/">"Green Hell".</a> The program works by reading in the serialized save file data, deserializing it into a list of objects, modifying the desired deserialized object, reserializes all of the objects, saves the reserialized objects to a new save file, and then automatically modifies the new save file to resolve linking issues. The final result is a work save file identical to the original, except for the desired settings changed. The current program only modifies the "insects enabled/disabled" settings in order to aid those with arachnophobia (or those who despise centipedes like myself). The framework for further manipulation is present, and further additions are planned. 
	    </ReferenceLink>
	    <ReferenceLink title="AI Generated Email Detector and Proxy (Python)"
			   link="https://gitlab.com/dswanky/cs-433-project">
		This project ingests email files and runs them through an "AI detector" program. The "AI detector" in this case, is a machine learning model trained to identify ChatGPT created text. The program ingests the email body, runs it through the AI detector, modifies the email if it is detected to be AI, and then forwards the email to its final destination. This was a final project for the University of Oregon CS 433 cybersecurity course.
	    </ReferenceLink>
	    <ReferenceLink title="Go Direct File Transfer (Go)"
			   link="https://gitlab.com/dswanky/go-direct-file-transfer">
		This project was written to create an easy way to send files directly from one computer to another. The motive for creating it was being tired of finding email to be the easiest way to quickly transfer files without setting up a server or syncing software. This project is written entirely in Go and uses TCP sockets for file transfers. I utilized this project to learn the Go programming language.
	    </ReferenceLink>
	</Container>
    )
}
