import Form from 'react-bootstrap/Form';

export default function FormControl({id, label, children}){
    return (
	<Form.Group controlId={id} className="Form">
	    <Form.Label>
		{label}
	    </Form.Label>
	    {children}
	</Form.Group>
    )
}
