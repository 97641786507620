import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputField from '../components/InputField.js'
import TextField from '../components/TextField.js';
import SubHeader from '../components/SubHeader.js';

function OnSubmit(ev){
    ev.preventDefault();
    let hadError = false;
    fetch("http://localhost:5000/contact", {
	method: 'POST',
	mode: "no-cors",
	headers: {"Content-Type" : "application/x-www-form-urlencoded"},
	body: new URLSearchParams({
	    "email" : ev.target.email.value,
	    "content" : ev.target.content.value,
	}),
    }).catch(error => {hadError = true; console.log(error);});
    if(!hadError) alert("Thank you for contacting me, I will review your request shortly.");
}

export default function ContactPage(){
    return (
	<Container>
	    <SubHeader title="Contact">
		<p className="header-subtext">Send me a message about jobs, my work, or just a general query.</p>
	    </SubHeader>
	    <Form onSubmit={OnSubmit}>
		<InputField id="email" type="email" label="Email Address" placeholder="Your Email Address"/>
		<br/>
	    <TextField id="content" rows={3} label="Message" placeholder="Your Message"/>
	    <Button variant="primary" type="submit">Submit</Button>
	    </Form>
	</Container>
    )
}
