import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from "react-bootstrap/Container";

function BuildLink(link){

    if(link.hasOwnProperty("type") && link.type == "dropdown"){
	return (
	    <NavDropdown title={link.text}>
		{link.sublinks.map(link => {
		    return (
			<NavDropdown.Item href={link.link}>
			    {link.text}
			</NavDropdown.Item>
		    );
		})}
	    </NavDropdown>
	)
	
    }else{
	return (
	    <Nav.Link href={link.link}>
		{link.text}
	    </Nav.Link>
	)
	
    }
    
}

export function GenerateLinks(links, className){
    return links.map(link => {
	return (
	    <Nav.Item className={className + " nav"}>
		{BuildLink(link)}
	    </Nav.Item>
	)
    })
    
}


export default function Header({title,children}) {
    return (
	<Navbar sticky="top" className="Header">
	    <Container>
		<Navbar.Brand>
		    {title}
		</Navbar.Brand>
		{children}
	    </Container>
	</Navbar>
    );
}

