import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';

import TitledStack from "./TitledStack.js"
import {GenerateLinks} from "./Header.js";

export default function Footer({titleLinks, links, children}){
    return (
	<Container>
	    {children}
	    <Stack direction="horizontal">
		{
		    titleLinks.map((titleLink, index) => {
			return (
			    <TitledStack titleLink={titleLink}>
				{/*GenerateLinks(links[index], "footer-links")*/}
			    </TitledStack>
			);
		    })
		}
	    </Stack>
	</Container>
    );
}
