import Container from 'react-bootstrap/Container';
import EvenGrid from '../components/Grid.js';
import BasicCard from '../components/CustomCard.js';
import BasicLink from '../components/BasicLink.js';

const gridObjs = [
    <BasicCard title="My C.V.">
	<BasicLink link="/resume" text="Link to C.V."/>
    </BasicCard>,
    <Container>is</Container>,
]


export default function HomePage(){
    return (
        ////////////////////////////////////////////////////////////////
	// <Container fluid className="App">			      //
        //     <Container>					      //
	// 	<EvenGrid colNum={3} gridItems={gridObjs}/>	      //
        //     </Container>					      //
        // </Container>						      //
	////////////////////////////////////////////////////////////////
	<>
	    <h3>I'm Derek Swank. A Software Developer Based in Eugene, Oregon.</h3>
	    <p>I am actively updating this site. Currently a work in progress, expect this page to get prettier soon.</p>
	    <p>In the meantime, feel free to check out my <a href="/resume">resume</a>.</p>
	</>
    );
}
