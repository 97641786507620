import Container from 'react-bootstrap/Container';
import SubHeader from '../components/SubHeader.js';

export default function AboutWebsite(){
    return (
	<Container>
	    <SubHeader title="About Website" />
	    <p>
		This website was fully created and developed by Derek Swank. The website front-end was written in React JS with Bootstrap as the UI framework. The website backend utilizes PyFlask to connect the front-end to a Postgres database. Blog posts are written in Org-Mode, converted to HTML, and retrieved from the Postgres database. The source code for this website will be posted to GitLab, and this page will be updated as the frameworks used expands.
	    </p>
	</Container>
    )
}
