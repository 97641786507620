import Form from 'react-bootstrap/Form';
import FormControl from './FormControl';

export default function TextField({id, label, rows, placeholder}){
    return (
	<FormControl id={id} label={label}>
	    <Form.Control as="textarea" rows={rows} placeholder={placeholder}/>
	</FormControl>
    )
}
